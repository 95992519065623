/*** General ***/
:focus-visible {
	outline: none !important;
}
body{
	font-family: 'Rubik';
}
a{
	cursor: pointer;
}
.default{
	color:#02224B !important;
}
.grey{
	color:#798590 !important;
}
.grey-2{
	color:#8C8C8C !important;
}
.white{
	color:#ffffff !important;
}
.black{
	color:#000000 !important;
}
.green{
	color:#00BB66 !important;
}
.red{
	color:#C80731 !important;
}
.blue{
	color:#2C3E50 !important;
}
.lightblue{
	color: rgba(2, 34, 75, 0.7) !important;
}
.darkblue{
	color: #053B81 !important;
}
.bold{
	font-weight: 700;
}
.alignLeft{
	display: flex;
	align-items: flex-start;
}
#warning{
	color: #C80731 !important;
}
#success{
	color: #00BB66 !important;
}
.coverBg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}
.coverBg img{
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.aboutNav{
	display: flex;
	align-items: center;
	text-transform: initial !important;
}
.navContainer{
	display: block;
	margin-top: 20px;
	margin-bottom: 35px;
	margin-right: -51px;
	overflow-y: scroll;
}
.navContainer .makeStyles-actived-30{
	margin-bottom: 0;
}
.navIcon{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	margin-right: 4px;
}
.navIcon svg{
	margin: 0 !important;
}
.mt-30{
	margin-top: 24px;
}
.mb-30{
	margin-bottom: 24px;
}
.mt-0{
	margin-top: 0 !important;
}
.textLeft{
	text-align: left !important;
}
.buttonSecondary{
	background-color: transparent !important;
}
.docBtn{
	margin-left: 20px;
}
.docBtn button{
	border: 1px solid #02224B !important;
}
.docBtn .MuiButton-label{
	color: #02224B !important;
}
.docBtn button:hover .MuiButton-label{
	color: #fff !important;
}
.topTitle{
	padding-bottom: 15px;
	border-bottom: 1px solid #DDDDDD;
}
.topTitle p{
	font-size: 14px;
	font-weight: 400;
	margin: 5px 0 0 0;
	color: #2C3E50;
}
.telegram{
	background-color: #039BE5 !important;
}
.footerButtons{
	margin-top: 40px;
	/* display: flex; */
	align-items: center;
	width: 100%;
}
.footerButtons .buttonSecondary{
	margin-left: 20px;
}
.tableFull a{
	display: contents;
	align-items: center;
	width: 100%;
}
.tableFull a .MuiTableRow-root{
	width: 100%;
}
.headerLogo{
	padding: 15px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
}
.headerLogo img{
	height: 40px;
}
.searchMobile,
.logoMobile,
.notiMobile{
	display: none;
}
.drawerButton{
	margin: 0 0 15px 0;
	text-align: end;
}
.personalForm .inputPrefix{
	display: flex;
	position: relative;
}
.personalForm .inputFlag {
	position: absolute;
	left: 15px;
	height: 15px;
	bottom: 37px;
}
.personalForm .inputPrefix .MuiOutlinedInput-root{
	padding-left: 40px;
}

/*** MUI override ***/
.MuiTabs-indicator{
	background-color: #37ED9A !important;
}
.MuiAccordionDetails-root{
	flex-direction: column;
}
.MuiAccordionSummary-content{
	align-items: center;
}
.MuiAccordion-root:before{
	display: none;
}
.MuiAccordion-rounded{
	border-radius: 0 !important;
	box-shadow: none !important;
	border-top: 1px solid #DDDDDD;
	border-bottom: 1px solid #DDDDDD;
}
.MuiList-root{
	border-radius: 5px;
	overflow: hidden;
}
.MuiPaper-root {
	z-index: 20;
}
.MuiButton-endIcon{
	margin-left: 8px !important;
}
.MuiChip-label{
	line-height: 1 !important;
}
.MuiPaginationItem-page.Mui-selected {
	background-color: #fff !important;
	border-color: #fff !important;
}
.MuiPaginationItem-outlined{
	background-color: transparent ;
	border-color: rgba(121, 133, 144, 0.2) !important;
}
.MuiPaginationItem-root{
	font-weight: 400 !important;
	color: #798590 !important;
}

/*** MUI Sidebar override ***/
.MuiListItemText-root{
	margin: 0 !important;
}
.MuiListItem-gutters{
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

/*** Tipografías colores ***/

.blue{
	color: #2C3E50 !important;
}

/*** Home ***/
.CarouselItem{
	padding-bottom: 10px;
	margin-bottom: 6%;
}
.CarouselItem h5{
	padding: 30px 115px 0 115px;
}
.MuiButton-label span{
	margin-left: 4px;
}
.loginLogo{
	position: absolute;
	top: 0;
	left: 0;
}
.homeBtn{
	margin-top: 40px !important;
}
.homeGrid{
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.homeImg{
	height: 80%;
	position: relative;
	margin-right: 100px;
}
.homeImg .coverBg{
	padding-right: 20px;
	padding-bottom: 60px;
	z-index: 2;
}
.homeBg{
	position: absolute;
	right: -25px;
	bottom: -60px;
	height: 20vw;
	width: 15vw;
	background-color: #37ED9A;
	z-index: 0;
}
.homeText{
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	max-width: 510px;
}
.homeLogo{
	width: 200px;
	display: block;
	margin-bottom: 30px;
}
.homeFooter{
	display: block;
	margin-top: 100px;
}
.boxSlider{
	height: auto;
	margin-bottom: 24px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	position: relative;
	overflow: hidden;
}
.boxSlider .Carousel-indicators-67{
	margin-bottom: 20px !important;
}
.boxLink{
	display: block;
	width: 100%;
	background-color: #fff;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	margin-bottom: 24px;
}
.boxTable {
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding: 30px 20px;
	display: block;
	width: calc(100% - 40px);
	margin-bottom: 100px;
}
.recomendaLogo{
	height: 45px;
	width: 45px;
	display: inline-flex;
}
.recomendaBox{
	display: flex;
	align-items: center;
	position: relative;
	padding: 16px 20px;
	width: calc(100% - 40px);
}
.formClose{
	display: none;
}

/*** Sidebar ***/

.textBtn{
	text-transform: initial !important;
	display: flex;
	align-items: center;
	font-size: 16px !important;
	line-height: 19px !important;
}
.textSub{
	text-transform: initial !important;
	display: flex;
	align-items: center;
	font-size: 16px !important;
	line-height: 19px !important;
	
}

/*** Inicio ***/

.boxArrow{
	margin-left: 4px;
	display: inline-flex;
}
.bannerColored{
	width: 100%;
	background-color: #37ED9A !important;
	padding: 15px 20px;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 15px;
	align-items: center;
}
.bannerIcon{
	display: inline-flex;
	width: 50px;
	margin-right: 25px;
}
.bannerIcon img{
	display: block;
	width: 100%;
}
.bannerText{
	display: inline-flex;
	align-items: center;
}
.bannerText p{
	padding: 0;
}
.bannerButton{
	display: inline-flex;
}

/*** Requisitos Home ***/

.formStatus{
	display: grid;
	grid-template-columns: 35px 1fr;
	grid-gap: 10px;
	align-items: center;
	margin-bottom: 20px;
}
.statusIcon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	background-color: #FFEAC0;
	border-radius: 5px;
}
.statusIcon img{
	display: block;
	width: 13px;
	height: 13px;
}
.statusText p{
	margin: 0;
}
.requisitosGrid{
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-bottom: 80px;
}
.requisitosImg{
	height: 100%;
	position: relative;
	border-bottom-left-radius: 12px;
}
.requisitosImg .coverBg{
	z-index: 2;
	border-bottom-left-radius: 12px;
	overflow: hidden;
	align-items: flex-start;
}
.requisitosBg{
	position: absolute;
	left: -40px;
	bottom: -40px;
	height: 20vw;
	width: 15vw;
	background-color: #37ED9A;
	z-index: 0;
}
.ayudaBg{
	position: absolute;
	left: -40px;
	bottom: -40px;
	height: 358px;
	width: 274px;
	background-color: #37ED9A;
	z-index: 0;
}
.requisitosText{
	display: flex;
	padding: 30px 20px;
	width: 100%;
	max-width: 400px;
}
.requisitosSubtitle{
	display: block;
}
.requisitosSubtitle p{
	margin-top: 5px;
}
.requisitosContainer{
	margin: 15px 0 0 0;
}
.requisitosFooter{
	margin-top: 30px;
}
.requisitosFooter a,
.requisitosFooter button{
	margin: 0;
}
.requisitosRow{
	display: grid;
	grid-template-columns: 70px 1fr;
	grid-gap: 25px;
	align-items: center;
	margin-bottom: 30px;
	align-items: center;
}
.requisitosRow p{
	margin: 0;
}
.requisitosRow h3{
	margin: 0 0 10px 0;
}
.requisitosIcon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 50px;
}
.requisitosIcon img{
	display: block;
	width: auto;
}
.formNav{
	display: none;
}

/*** Requisitos Form ***/

.requisitosTitle{
	padding-bottom: 15px;
	border-bottom: 1px solid #DDDDDD;
}
.requisitosTitle p{
	font-size: 14px;
	font-weight: 400;
	margin: 5px 0 0 0;
	color: #2C3E50;
}
.requisitosTab{
	display: grid;
	width: 100%;
	grid-template-columns: 240px 1fr;
	grid-gap: 20px;
	margin-top: 30px;
}
.requisitosInput{
	display: flex;
	flex-direction: column;
}
.requisitosBox{
	display: block;
	width: 100%;
	margin-bottom: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid #ddd;
}
.requisitosButton{
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;
	padding: 16px 10px;
	position: relative;
	border-radius: 5px;
	background-color: transparent;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.requisitosBox:hover .requisitosButton{
	background-color: #fff;
}
.requisitosBox.selected .requisitosButton{
	background-color: #fff;
}
.requisitosBox.pending .requisitosButton{
	background-color: #FFEAC0;
	color: #FFAF13;
}
.requisitosBox.checked .requisitosButton{
	background-color: #9FF6CF;
	color: #27865B;
}
.requisitosBox.error .requisitosButton{
	background-color: #FFE7EC;
	color: #C80731;
}
.requisitosName{
	font-size: 14px;
	font-weight: 400;
}
.requisitosBox.selected .requisitosName{
	font-weight: 500;
	color: #063878;
}
.requisitosRight{
	display: flex;
	align-items: center;
}
.requisitosRight i{
	margin-left: 4px;
}
.requisitosRight span{
	display: inline-flex;
	font-size: 14px;
	font-weight: 400;
}
.requisitosBox.selected .requisitosRight span{
	font-weight: 500;
	color: #063878;
}
.requisitosForm{
	display: block;
	position: relative;
}
.boxEstado{
	display: flex;
}
.boxEstado i{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 13px;
	height: 13px;
}
.boxPendiente i{
	display: none;
	align-items: center;
	justify-content: center;
	width: 13px;
	height: 13px;
	border-radius: 100%;
	color: #FFAF13;
	border: 1px solid #FFAF13;
	box-sizing: border-box;
}
.boxPendiente svg{
	width: 100%;
	height: 100%;
}
.boxOk i{
	display: none;
	align-items: center;
	justify-content: center;
	width: 13px;
	height: 13px;
	border-radius: 100%;
	color: #27865B !important;
	border: 1px solid #27865B;
	box-sizing: border-box;
}
.boxOk svg{
	width: 100%;
	height: 100%;
}
.boxError i{
	display: none;
	align-items: center;
	justify-content: center;
	width: 13px;
	height: 13px;
	font-size: 12px;
	border-radius: 100%;
	font-style: normal !important;
	color: #C80731 !important;
	border: 1px solid #C80731;
	box-sizing: border-box;
}
.boxError span{
	display: none;
}
.boxError svg{
	width: 100%;
	height: 100%;
}
.requisitosBox.pending .boxPendiente i{
	display: inline-flex;
}
.requisitosBox.checked .boxOk i{
	display: inline-flex;
}
.requisitosBox.error .boxError i,
.requisitosBox.error .boxError span{
	display: inline-flex;
}
.requisitosBox.pending .boxEstado i,
.requisitosBox.pending .boxEstado span{
	display: none;
}
.requisitosBox.checked .boxEstado i,
.requisitosBox.checked .boxEstado span{
	display: none;
}
.requisitosBox.error .boxEstado i,
.requisitosBox.error .boxEstado span{
	display: none;
}
.formContainer{
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 60px;
	left: 0;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	padding: 30px 20px;
	width: 20vw;
	transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
	-webkit-transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}
.formContainer.active{
	opacity: 1;
	top: 0;
	pointer-events: visible;
}
.formControl{
	display: block;
	position: relative;
	margin-bottom: 30px;
}
.formControl:last-of-type{
	margin-bottom: 0;
}
.formControl input{
	margin: 0;
	width: 100%;
}
.formControlFull{
	display: block;
	position: relative;
	margin-left: 0px;
    margin-right: 20px;
	margin-bottom: 30px;
}
.formControlFull:last-of-type{
	margin-bottom: 0;
}
.formControlFull input{
	margin-left: 0px;
    width: 100%;
}
.formControlFull.inputError .makeStyles-input-45{
	border-color: #C80731 !important;
	color: #C80731 !important;
}
.formControlFull span{
	display: none;
}
.formControlFull span.formError{
	display: block;
	font-size: 12px;
	font-weight: 400;
	color: #C80731;
	margin-top: 5px;
}
.formControlFull span.formObs{
	display: block;
	font-size: 12px;
	font-weight: 400;
	color: #8C8C8C;
	margin-top: 5px;
	text-align: right;
}
.formControlFull span.formObs.obsSuccess{
	color: #27865B;
	text-align: left;
}

.formContainer button{
	margin-top: 40px;
	display: inline-flex;
}
.formSelect {
	position: relative;
}
.formSelect .MuiInputBase-root {
	margin-top: 9px;
	cursor: default !important;
}
.formSelect input{
	cursor: default !important;
	pointer-events: none !important;
	color: transparent;
  text-shadow: 0 0 0 #000;
}
.formSelect input:focus {
  outline: none;
}
.formSelect input:focus {
	outline: none;
  }
.formSelect .MuiInputAdornment-positionEnd{
	pointer-events: none !important;
}
.formControl span{
	/* display: none; */
}
.formControl span.formError{
	display: block;
	font-size: 12px;
	font-weight: 400;
	color: #C80731;
	margin-top: 5px;
}
.formControl.inputError .makeStyles-input-45{
	border-color: #C80731 !important;
	color: #C80731 !important;
}
.formControl span.formObs{
	display: block;
	font-size: 12px;
	font-weight: 400;
	color: #8C8C8C;
	margin-top: 5px;
	text-align: right;
}
.formControl span.formObs.obsSuccess{
	color: #27865B;
	text-align: left;
}
.formLabel{
	display: block;
	font-size: 12px;
	font-weight: 400;
	color: #02224B;
	margin-bottom: 9px;
}
.formLarge{
	width: 42vw;
}
.form_2{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 25px;
}
.form_3{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 25px;
}
.form_3 .formBig{
	grid-column: span 2;
}
.form_3_2{
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 25px;
}
.form_2_1{
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 25px;
}
.form_1_2{
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: 25px;
}
.formColumn{
	margin-bottom: 30px;
}
.formColumn:last-of-type{
	margin-bottom: 0;
}
.formColumn .formControl{
	margin-bottom: 0;
}
.formControl.formFull .formUpload{
	width: 100%;
}
.formControl .formUpload{
	display: inline-block;
	padding: 14px 10px;
	background-color: #f9f9f9;
	border: 1px solid #d1d1d1;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	height: 46.63px !important;
	width: auto;
	min-width: 200px;
	box-shadow: none;
	-webkit-box-shadow: none;
}
.formControl .formUpload:hover{
	background-color: #eee;
}
.formControl .formUpload span{
	display: block;
}
.formControl .uploadDisabled{
	color: #8c8c8c !important;
	background-color: #f3f3f3 !important;
	border: none !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	pointer-events: none !important;
}
.formAlert{
	display: flex;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 60px;
	left: 0;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	width: 100%;
	max-width: 500px;
	overflow: hidden;
	transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
	-webkit-transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}
.alertWrapper{
	display: grid;
	grid-template-columns: 55px 1fr;
}
.formAlert.active{
	opacity: 1;
	pointer-events: visible;
	top: 0;
}
.formControl.formFull .MuiInputBase-root{
	width: 100%;
}
.alertBg{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #FFEAC0;
	position: relative;
}
.alertIcon{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.alertInfo{
	padding: 20px;
}
.alertInfo h3{
	font-size: 14px;
	font-weight: 500;
	margin: 0;
}
.alertInfo p{
	color: #02224B;
	font-size: 12px;
	font-weight: 400;
	margin: 5px 0 0 0;
}
.formSuccess{
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 60px;
	left: 0;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	width: 100%;
	max-width: 500px;
	transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
	-webkit-transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}
.formSuccess.active{
	opacity: 1;
	pointer-events: visible;
	top: 0;
}
.formSubtitle{
	display: block;
	margin-bottom: 10px;
}
.formSubtitle h4{
	margin: 0;
}
.formRadio{
	display: block;
	margin-top: 20px;
}
.radioRow{
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.radioRow:last-child{
	margin-bottom: 0;
}
.radioRow p{
	margin: 0;
	display: inline-flex;
}
.radioIcon{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	border-radius: 100%;
	border: 2px solid #02224B;
	margin-right: 10px;
}
.radioIcon img{
	display: none;
	width: 70%;
}
.radioActive img{
	display: block;
}
.radioActive .radioIcon{
	border-color: #8c8c8c;
}
.radioActive p{
	color: #8c8c8c !important;
}
.formProgress{
	display: block;
	margin-top: 20px;
}
.formCta{
	display: block;
	margin-top: 30px;
}
.formCta button{
	margin-top: 0 !important;
}
.formCta p{
	margin: 0;
}
.formCta .shareIcons{
	margin-top: 15px;
}
.formCta .shareButton:first-of-type svg{
	width: 60%;
	height: 60%;
}
.buttonLink{
	display: block;
	text-decoration: none !important;
	font-weight: 500;
	font-size: 14px;
	color: #02224B;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.buttonLink:hover{
	color: #37ED9A;
}
.formCta .buttonLink{
	margin-top: 20px;
}
.formPlans{
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 60px;
	left: 0;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	width: 100%;
	max-width: 500px;
	transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
	-webkit-transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}
.step-grid{
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-gap: 10px;
	align-items: center;
	margin-top: 20px;
}
.step-icon{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	background-color: #37ED9A;
	border-radius: 5px;
	margin-right: 10px;
}
.step-icon path{
	fill: #02224B;
}
.step-box.inactive .step-icon{
	background-color: #D1D1D1;
}
.step-box.inactive .step-icon path{
	fill: #8c8c8c;
}
.step-box.ok .step-icon{
	background-color: #37ED9A;
}
.step-box.ok .step-icon path{
	fill: #02224B;
}
.step-box.next .step-icon{
	background-color: #AEFBD8;
}
.step-box.next .step-icon path{
	fill: #27865B;
}
.step-box{
	display: flex;
	align-items: center;
}
.stepLine{
	display: block;
	width: 100%;
	height: 1px;
	background-color: #000;
}
.stepList{
	margin: 30px 0;
}
.stepList p{
	font-size: 12px;
	margin-bottom: 10px;
	color: #2C3E50;
}
.stepList p:last-child{
	margin-bottom: 0;
}
.stepList a{
	color: #2C3E50;
	font-weight: 500;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.stepList a:hover{
	color: #37ED9A;
}
.planButtons{
	display: block;
	margin-top: 20px;
}
.planBtn{
	cursor: pointer;
	padding: 20px;
	display: grid;
	grid-template-columns: 20px 1fr;
	grid-gap: 20px;
	align-items: center;
	background-color: #fff;
	border-radius: 10px;
	margin-bottom: 10px;
	border: 1px solid #D1D1D1;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.planBtn2{
	cursor: pointer;
	display: grid;
	grid-template-columns: 20px 1fr;
	grid-gap: 20px;
	align-items: center;
	margin-bottom: 10px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.planBtn:hover{
	background-color: #F9F9F9;
	box-shadow: none;
	-webkit-box-shadow: none;
}

.planRadio{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 100%;
	border: 1px solid #02224B;
}
.planRadio:before{
	opacity: 0;
	content: '';
	width: 16px;
	height: 16px;
	border-radius: 100%;
	background-color: #37ED9A;
}
.planBtn.selected .tabsRadio{
	border-color: #02224B;
}
.planBtn.selected .planRadio:before{
	opacity: 1;
}
.planBtn.inactive{
	background-color: #E8E8E8;
	box-shadow: none;
	-webkit-box-shadow: none;
}
.planDetail{
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 15px;
	width: 100%;
	align-items: center;
}
.planText{
	display: block;
	width: 100%;
	max-width: 170px;
}
.planText p{
	margin: 0;
}
.planText span{
	display: block;
	color: #8C8C8C;
}
.planRight{
	display: flex;
	flex-direction: column;
}
.planPrice{
	display: flex;
	align-items: center;
	line-height: 1;
}
.planPrice span{
	display: block;
}
.planPrice h5{
	margin: 0;
	font-weight: 700;
	font-size: 24px;
	color: #02224B;
}
.planRight p{
	color: #02224B;
	font-size: 10px;
	font-weight: 400;
	line-height: 1.4;
	display: block;
	margin: 0;
	width: 100%;
	max-width: 60px;
}
.plantCta{
	margin-top: 40px;
	display: flex;
	align-items: center;
}
.plantCta a,
.plantCta button{
	margin: 0 20px 0 0 !important;
}
.plantCta a:last-child,
.plantCta button:last-child{
	margin-right: 0 !important;
}
.priceGrid{
	display: grid;
	grid-template-columns: repeat(4,1fr);
	grid-gap: 10px;
	overflow: auto;
}
.priceBox{
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}
.priceBox.priceSelected{
	border: 1px solid #02224B;
}
.priceTitle h3{
	margin: 0 0 5px 0;
}
.priceTitle p{
	margin-bottom: 15px;
}
.priceQty{
	display: flex;
	align-items: center;
}
.priceAmount{
	display: flex;
	align-items: center;
	margin-right: 5px;
}
.priceAmount span{
	font-weight: 500;
	font-size: 14px;
	color: #02224B;
	display: inline-flex;
}
.priceAmount h5{
	margin: 0;
	font-weight: 700;
	font-size: 24px;
	color: #02224B;
}
.priceQty p{
	margin: 0;
	color: #02224B;
	font-size: 12px;
	font-weight: 400;
}
.priceQty strong{
	font-weight: 500;
}
.priceList{
	display: block;
	margin-top: 20px;
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #e8e8e8;
}
.priceRow{
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.priceRow:last-child{
	margin-bottom: 0;
}
.priceRow p{
	font-size: 12px;
	font-weight: 400;
	color: #2C3E50;
	margin: 0;
}
.priceRow strong{
	font-weight: 500;
}
.priceRow img{
	display: inline-flex;
	margin-right: 5px;
}
.priceBox button,
.priceBox a{
	width: 100%;
}
.priceInfo{
	display: block;
	margin: 15px 0 40px 0;
}
.priceInfo p{
	font-size: 12px;
	font-weight: 400;
	color: #2C3E50;
	margin: 0 0 8px 0;
}
.priceInfo p:last-child{
	margin-bottom: 0;
}
.priceTag{
	position: absolute;
	top: 20px;
	right: 20px;
	background-color: #37ED9A;
	color: #02224B;
	font-size: 10px;
	font-weight: 400;
	padding: 4px 8px;
	border-radius: 20px;
}
.priceBox.priceSelected button,
.priceBox.priceSelected a{
	background-color: #37ED9A !important;
	color: #02224B !important;
}
.priceBox.priceSelected button:hover,
.priceBox.priceSelected a:hover{
	background-color: #02224B !important;
	color: #fff !important;
}
.priceObs{
	display: block;
	margin-top: 20px;
}
.priceObs p{
	margin: 0;
}
/*** Compartir Link ***/

.shareBtn{
	margin-top: 40px !important;
}
.shareGrid{
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 2.5fr;
	grid-gap: 30px;
}
.shareImg{
	height: 80%;
	position: relative;
}
.shareImg .coverBg{
	padding-right: 20px;
	padding-bottom: 60px;
	z-index: 2;
}
.shareBg{
	position: absolute;
	right: -25px;
	bottom: -40px;
	height: 20vw;
	width: 15vw;
	background-color: #37ED9A;
	z-index: 0;
}
.shareText{
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
}
.shareInfo{
	display: block;
	padding: 30px 20px;
	margin-bottom: 140px;
	width: 100%;
}
.shareForm{
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 15px;
	align-items: flex-end;
	margin-top: 40px;	
	width: 100%;
	max-width: 30vw;	
}
.shareFooter{
	display: block;
	margin-top: 30px;
	padding-top: 25px;
	margin-top: 25px;
	border-top: 1px solid #E5E5E5;
}
.shareFooter p{
	margin: 0;
}
.shareIcons{
	display: flex;
	align-items: center;
	margin-top: 10px;
}
.shareButton{
	background-color: #EAF0F8;
	display: inline-flex !important;
	align-items: center !important;
	padding: 8px 15px !important;
	margin-right: 20px !important;
}
.shareButton:last-of-type{
	margin-right: 0 !important;
}
.shareButton span{
	display: inline-flex;
	align-items: center;
	font-size: 12px;
	font-weight: 400;
}
.shareButton svg{
	margin-right: 5px;
}
.linkMobile{
	display: none;
}
.linkHidden{
	display: none;
}

/*** Movimientos ***/

.movimientosBox{
	width: auto !important;
	padding: 20px 20px;
	background: #FFFFFF;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
}
.movimientosBox p{
	margin: 0;
}
.movimientosTitle{
	display: block;
	margin-bottom: 20px;
}
.movimientosTitle h3{
	margin: 0;
}
.movimientosRow{
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 20px;
}
.movimientosRow:last-child{
	margin-bottom: 0 !important;
}
.movimientosRow p,
.movimientosTotal p{
	margin: 0;
}
.movimientosTotal{
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid #d1d1d1;
}
.movimientosTotal.b-0{
	margin-top: 0 !important;
	padding-top: 0 !important;
	border: none !important;
}
.movimientosDetail{
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 20px;
	align-items: center;
}
.movimientosID{
	display: flex;
	align-items: center;
}
.movimientosID p{
	display: inline-flex;
	margin-right: 20px;
}
.movimientosID span{
	display: inline-flex;
	margin-right: 20px;
}
.movimientosState{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.stateAlign{
	display: flex;
	align-items: center;
}
.movimientosState p{
	display: inline-flex;
	margin-right: 20px;
}
.movimientosState p:last-child{
	margin-right: 0;
	margin-left: 40px;
}
.movimientosBlock{
	box-sizing: border-box;
}
.movimientosBlock .movimientosBox{
	margin-bottom: 24px;
}
.movimientosBlock .movimientosBox:last-child{
	margin-bottom: 0;
}
.movimientosProduct{
	display: flex;
	align-items: center;
}
.movimientosProduct span{
	text-decoration: none;
	margin-left: 40px;
}
.movimientosProduct .link_detail{
	text-decoration: none;
	margin-left: 10px;
}
.movimientosImg{
	display: inline-flex;
	margin-right: 10px;
}
.movimientosUser{
	display: flex;
	align-items: center;
}
.movimientosName{
	display: flex;
	align-items: center;
}
.movimientosIcon{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background-color: #37ED9A;
	margin-right: 20px;
}
.movimientosIcon span{
	display: block;
	color: #000;
	font-size: 16px;
	font-weight: 500;
}
.movimientosUser a{
	text-decoration: none;
	margin-left: 40px;
}
.movimientosResumen .MuiToolbar-root{
	display: none;
}
.movimientosResumen{
	padding: 10px 20px 20px 20px !important;
}
.sellerTitle{
	display: block;
}
.sellerTitle h4{
	margin: 0 0 20px 0;
}
.sellerTitle p{
	margin: 0;
}
.sellerName{
	display: flex;
	align-items: center;
}
.sellerIcon{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 15px;
}
.sellerIcon img{
	display: block;
	width: 100%;
}
.sellerDetail{
	display: flex;
	align-items: center;
	margin-top: 15px;
	padding-top: 18px;
	border-top: 1px solid #D1D1D1;
}
.sellerInfo{
	display: flex;
	align-items: center;
	margin-left: 20px;
}
.sellerHead{
	display: inline-flex;
	align-items: flex-end;
}
.sellerHead p{
	margin: 0;
}
.sellerHead span{
	display: inline-flex;
	margin-left: 20px;
}
.sellerDetail a{
	display: inline-flex;
	margin-left: 40px;
	text-decoration: none !important;
}
.filterBox div{
	z-index: 20;
}
.paginationBox{
	margin-top: 40px;
}
.paginationBox ul li:first-of-type svg,
.paginationBox ul li:last-of-type svg{
	display: none;
}
.paginationBox ul li:first-of-type button,
.paginationBox ul li:last-of-type button{
	border: none !important
}
.paginationBox ul li:first-of-type button:before{
	content: 'Anterior';
	font-size: 12px;
	color: #798590;
	display: inline-flex;
}
.paginationBox ul li:last-of-type button:before{
	content: 'Siguiente';
	font-size: 12px;
	color: #798590;
	display: inline-flex;
}
.tableMobile{
	display: none;
}
.financeMobile{
	display: none;
}

/*** Perfil ***/

.profileTop{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: visible;
	width: 100%;
	max-width: 466px;
	margin: 0 auto;
	margin-top: 30px;
}
.profileCover{
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
	border-radius: 10px;
	height: 140px;
	width: 100%;
}
.profileInfo{
	width: 100%;
	max-width: 345px;
	position: absolute;
	z-index: 10;
	display: flex;
	flex-wrap: wrap;
    align-content: center;
    justify-content: center;
	flex-direction: column;
	top: 62px;
}
.profileInfo h3{
	font-size: 24px;
	font-weight: 700;
	margin: 40px 0 0 0;
}
.profileIcon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 115px;
	height: 115px;
	border-radius: 100%;
	overflow: hidden;
	margin: auto;
}
.profileIcon img{
	width: 100%;
	height: auto;
}
.profileContainer{
	display: block;
	width: 100%;
	max-width: 465px;
	margin: 0 auto;
	margin-top: 100px;
}
.acceptContainer{
	display: block;
	width: 100%;
	max-width: 465px;
	margin: 0 auto;
	margin-top: 50px;
}
.profileBox{
	padding: 30px 20px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	margin-bottom: 20px;
}
.profileBox h3{
	margin: 0;
}
.profileForm{
	margin-top: 30px;
}
.profileForm .formControl:last-of-type{
	margin-bottom: 0;
}
.profileBox .MuiAccordion-rounded{
	border: none !important;
}
.profileBox .MuiAccordionSummary-content{
	margin: 0 !important;
}
.profileBox .MuiAccordionSummary-root{
	min-height: auto !important;
}
.profileContent .MuiAccordionDetails-root{
	padding: 30px 0 0 0;
}
.profileContent .formObs{
	position: absolute;
	top: 0;
	right: 0;
	margin-top: 0 !important;
}

/*** Datos personales ***/
.datosGrid{
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: 20px;
	margin-top: 30px;
}
.datosProfile{
	padding: 30px 20px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}
.datosProfile h4{
	margin: 0 0 6px 0;
}
.datosProfile p{
	margin: 0;
}
.datosUpload{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 96px;
	height: 96px;
	border-radius: 100%;
	margin: 0 auto;
	margin-top: 40px;
	padding: 4px;
	border: 0.5px dashed #DDDDDD;
	position: relative;
}
.datosUpload label{
	cursor: pointer;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	position: relative;
	overflow: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.datosUpload label:hover{
	opacity: 0.8;
}
.datosUpload .MuiBox-root{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: calc(100% - 14px);
	height: calc(100% - 14px);
	border-radius: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.datosUpload .MuiBox-root img{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.datosUpload.uploadInactive{
	pointer-events: none;
}
.datosBtn{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	background-color: #2C3E50;
	position: absolute;
	top: 0;
	bottom: 0;
	right: -8px;
	z-index: 10;
	margin: auto;
}
.datosBtn img{
	width: 70%;
}
.datosForm{
	padding: 30px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}
.datosRow{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
	width: 100%;
	max-width: 35vw;
}

/*** Comunicaciones ***/
.comunicacionesBox{
	padding: 20px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	margin-top: 30px;
}
.comunicacionesSingle{
	padding: 30px 20px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	margin-top: 40px;
}
.comunicacionesBox h4{
	margin: 0 0 20px 0;
}
.comunicacionesRow{
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
.comunicacionesRow:last-child{
	margin-bottom: 0;
}
.comunicacionesRow p{
	margin: 0;
	display: flex;
	align-items: center;
}
.comunicacionesRow label span{
	margin: 0;
	display: flex;
	align-items: center;
}
.comunicacionesRow label{
	margin-left: 0;
}
.comunicacionesRow .MuiSwitch-input {
	left: 0;
	width: auto;
}
.comunicacionesRow .MuiSwitch-switchBase{
	top: initial;
}

/*** Detalles Financieros ***/
.detallesContainer{
	display: block;
	width: 100%;
	max-width: 50vw;
	padding: 0 20px;
	margin: 0 auto;
}

/*** Seguridad ***/
.passBox{
	display: flex;
	align-items: center;
	padding: 26px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}
.passBox p{
	margin: 0;
}
.passBox img{
	margin-right: 8px;
}
.passBox a{
	margin-left: 20px;
}

/*** Links Pago ***/
.linkBox{
	display: block;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	overflow: hidden;
}
.linkCover{
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 125px;
}
.linkWrapper{
	padding: 30px 20px;
	display: block;
}
.linkTitle{
	display: block;
	width: 100%;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #DDDDDD;
}
.linkTitle h3{
	font-size: 24px;
	font-weight: 700;
	color: #2C3E50;
	margin: 0 0 10px 0;
}
.linkTitle p{
	margin: 0;
}
.linkColumns{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 40px;
}
.accordionTab{
	padding: 0 !important;
}
.accordionTab i{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 12px;
	height: 12px;
	margin-left: 5px;
}
.accordionTab i img{
	display: block;
	width: 100%;
}
.footerButtons .makeStyles-buttonx-19{
	margin: 0;
}
.proColumns{
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3,1fr);
	grid-gap: 40px;
	align-items: center;
}
.proColumns .formControl{
	margin-bottom: 0;
}
.proSingle{
	margin-top: 30px;
	display: block;
}
.proContainer{
	margin: 0 !important;
	z-index: 0 !important;
}
.proContainer .MuiAccordionDetails-root{
	padding: 5px 0 30px 0;
}
.proContainer .MuiAccordionSummary-content.Mui-expanded {
	margin: 12px 0;
}
.proContainer .MuiAccordionSummary-root.Mui-expanded {
	min-height: 48px;
}
.tabObs{
	margin-left: 10px;
	display: inline-block;
}
.linkShare{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	align-items: center;
	margin-top: 30px;
	width: 100%;
	max-width: 1170px;
}
.linkBg{
	display: block;
	height: 100%;	
	border-radius: 10px;
	overflow: hidden;
}
.linkProduct{
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	grid-gap: 15px;	
	padding: 15px;
	height: calc(100% - 30px);
	background: #E5E5E5;
}
.linkImg{
	position: relative;
	overflow: hidden;
	border-radius: 5px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.linkDetail h3{
	font-weight: 700;
	font-size: 24px;
	margin: 0 0 5px 0;
}
.linkDetail p{
	margin: 0;
}
.linkDetail span{
	display: block;
	margin-top: 25px;
}
.linkURL{
	margin: 30px 0;
}
.linkURL .shareFooter{
	padding-top: 0;
	border: none;
}
.linkURL .shareForm{
	margin: 0;
}
.linkList{
	display: block;
	padding: 30px 20px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	margin-top: 24px;
}
.creditList{
	display: block;
	padding: 10px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	margin-top: 24px;
}
.linkSubtitle{
	display: block;
	width: 100%;
	margin-bottom: 10px;
	padding-bottom: 20px;
	border-bottom: 1px solid #DDDDDD;
}
.linkSubtitle h3{
	font-size: 18px;
	font-weight: 500;
	margin: 0 0 0 15px;
}
.linkRow{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
	border-bottom: 1px solid #DDDDDD;
}
.creditRow{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
}
.linkPreview{
	display: flex;
	align-items: center;
}
.cardPreview{
	display: flex;
	align-items: center;
	width: 100%;
}
.cardPreview img{
	display: inline-block;
	margin-right: 10px;
}
.linkPreview img{
	display: inline-block;
	margin-right: 10px;
}
.linkPreview p{
	margin: 0;
}
.cardPreview p{
	margin-left: 20%;
}
.linkPreview a{
	transition: all 0.3s ease-in-out;
}
.cardPreview a{
	transition: all 0.3s ease-in-out;
}
.linkPreview a:hover{
	opacity: 0.7;
}
.cardPreview a:hover{
	opacity: 0.7;
}
.linkRight{
	display: flex;
	align-items: center;
}
.linkState{
	display: grid;
	grid-template-columns: 80px 70px 140px;
	grid-gap: 40px;
	align-items: center;
	margin-right: 60px;
}
.linkState p{
	text-align: left;
}
.linkState .linkTag{
	display: flex;
}
.linkActions{
	display: flex;
	align-items: center;
}
.linkRounded{
	display: inline-flex;
	align-items: center;
	margin-left: 20px;
}
.linkRounded span{
	margin-right: 15px;
}
.linkRounded span span{
	margin: 0;
}
.linkRounded button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	min-width: initial;
	height: 25px;
	padding: 0 !important;
	border-radius: 100%;
	background-color: #E5E5E5;
	transition: all 0.3s ease-in-out;
}
.linkRounded button:hover{
	background-color: #37ED9A;
}
.linkTop{
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #DDDDDD;
}
.linkTop h3{
	font-size: 24px;
	font-weight: 700;
	color: #2C3E50;
	margin: 0 0 10px 0;
}
.linkFilter{
	display: flex;
	justify-content: flex-end;
}
.modalBox{
	padding: 30px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}
.modalLinkTitle{
	display: grid;
	grid-template-columns: 45px 1fr;
	grid-gap: 10px;
	align-items: center;
	border-bottom: 1px solid #DDDDDD;
	margin-bottom: 20px;
	padding-bottom: 20px;
}
.modalLinkTitle h3{
	margin: 0;
}
.modalLinkIcon{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	height: 45px;
	border-radius: 100%;
	background-color: #9FF6CF;
}
.modalBox .linkURL{
	margin: 0;
}
.modalPrice{
	width: 100%;
	max-width: 1070px;
	background-color: #f3f3f3;
}
.linkHidden{
	display: none;
}
.formDate .MuiFormControl-root{
	width: 100% !important;
	margin: 0 !important;
}
.formDate .MuiInputBase-root{
	border: 1px solid #d1d1d1;
	border-radius: 3px;
	margin-top: 9px;
}
.formDate .MuiFormControl-root input{
	padding: 14px 10px;
}
.formDate .MuiInput-underline:before,
.formDate .MuiInput-underline:after{
	display: none;
}
.formDate .MuiInputLabel-formControl{
	position: relative;
	font-weight: normal !important;
	font-size: 12px !important;
	line-height: 14px !important;
	color: #02224B;
	transform: none !important;
	-webkit-transform: none !important;
}

/*** Colaboradores ***/
.colabBox{
	padding: 20px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	margin-top: 30px;
	position: relative;
}
.colabHeader{
	display: grid;
	grid-template-columns: repeat(6,1fr);
	align-items: center;
	padding-bottom: 20px;
	border-bottom: 1px solid #DDDDDD;
}
.colabRow{
	display: grid;
	grid-template-columns: repeat(6,1fr);
	align-items: center;
	padding: 15px 0;
	border-bottom: 1px solid #DDDDDD;
}
.colabHeader p{
	font-weight: 500;
	font-size: 14px;
	color: #02224B;
	margin: 0;
}
.colabRow p{
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	color: #02224B;
}
.colabButton{
	display: flex;
	justify-content: flex-end;
	margin-right: 30px;
}
.colabActions{
	display: flex;
	justify-content: flex-end;
	margin-right: 30px;
}
.colabActions .linkRounded span{
	margin-right: 10px;
}
.colabActions .linkRounded span span{
	margin: 0 !important;
}
.colabActions a{
	cursor: pointer;
}
.colabData{
	display: grid;
	align-items: center;
	grid-template-columns: 1fr auto;
	grid-gap: 30px;
}
.colabInput{
	display: grid;
	grid-template-columns: repeat(2,1fr);
	grid-gap: 40px;
	width: 100%;
	max-width: 768px;
}
.colabInput .formControl{
	margin: 0;
}
.colabUser{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;
	width: 40px;
	cursor: pointer;
}
.colabDots{
	display: flex;
	flex-direction: column;
}
.colabDots span{
	display: block;
	width: 4px;
	height: 4px;
	border-radius: 4px;
	background-color: #8C8C8C;
	margin-bottom: 4px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.colabDots span:last-child{
	margin-bottom: 0;
}
.colabUser:hover .colabDots span{
	background-color: #37ED9A;
}
.colabEdit{
	opacity: 0;
	pointer-events: none;
	position: absolute;
	padding: 20px;
	background: #FFFFFF;
	border: 0.3px solid #D1D1D1;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	right: 15px;
	top: 70px;
	z-index: 10;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.colabUser:hover .colabEdit{
	opacity: 1;
	top: 50px;
	pointer-events: visible;
}
.colabOption{
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	white-space: nowrap;
	margin-bottom: 15px;
}
.colabOption:last-child{
	margin-bottom: 0;
}
.colabOption span{
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	color: #2C3E50;
	margin-left: 10px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.colabOption span:hover{
	color: #37ED9A;
}
.colabRol h3{
	margin: 0 0 10px 0;
}
.colabInfo{
	display: flex;
}
.colabInfo img{
	width: 30px;
	height: 30px;
}
.colabInfoDetail{
	display: block;
	margin-left: 20px;
}
.colabInfoDetail p{
	margin: 0;
}
.colabSettings{
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}
.colabSettings:last-child{
	margin-bottom: 0;
}
.colabSettings label span{
	margin: 0;
	display: flex;
	align-items: center;
}
.colabSettings label{
	margin-left: 0;
}
.colabSettings .MuiSwitch-input {
	left: 0;
	width: auto;
}
.colabSettings .MuiSwitch-switchBase{
	top: initial;
}
.colabSettings .colabInfoDetail{
	width: 470px;
}
.colabSettings .colabInfo{
	margin-bottom: 20px;
}
.colabSettings .colabInfo:last-child{
	margin-bottom: 0;
}
.modalTitle{
	display: block;
	margin-bottom: 20px;
}
.modalTitle h3{
	margin: 0;
}
.modalInputs{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 40px;
	margin-bottom: 20px;
}
.modalInputs .formControl{
	margin-bottom: 0;
}
.shareColaboradores h4{
	margin: 0 0 15px 0;
}
.shareIcon{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 19px;
	height: 19px;
	border-radius: 100%;
	background-color: #2C3E50;
}
.shareIcon svg {
	margin: 0 !important;
}
.modalBox .footerButtons{
	margin-top: 0;
}

/*** Configuracion cobros ***/
.configBox{
	padding: 5px 20px 18px 20px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	margin-top: 30px;
}
.configLinks{
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 30px;
	align-items: center;
}
.userTab{
	background-color: transparent;
	opacity: 1 !important;
	display: inline-flex;
	align-items: center;
	padding-bottom: 5px;
	bottom: -3px;
	position: relative;
	min-height: 46px !important;
	padding: 0 !important;
	margin-right: 20px !important;
	border-bottom: 2px solid transparent;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.userTab.Mui-selected span{
	font-weight: 500;
}
.userTab .MuiTouchRipple-root {
	display: none;
}
.userTab button{
	padding: 0;
}
.userTab span {
	display: grid;
	align-items: center;
	grid-template-columns: 20px 1fr;
	grid-gap: 10px;
	text-transform: none;
	font-weight: 400;
	font-size: 14px;
	color: #2C3E50;
	text-decoration: none !important;
	transition: all 0.3s ease-in-out;
}
.userTab:last-child{
	margin-right: 0 !important;
}
.userTab span svg{
	margin: 0 !important;
}
.userTab:hover{
	border-color: #37ED9A;
	font-weight: 500;
}
.userTab img{
	margin-right: 10px;
}
.configSocial{
	display: flex;
	align-items: center;
}
.socialItem img{
	margin-right: 10px;
}
.socialItem{
	display: inline-flex;
	align-items: center;
	text-decoration: none !important;
	font-weight: 500;
	font-size: 14px;
	color: #02224B;
	margin-right: 20px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.socialItem:hover{
	color: #37ED9A;
}
.configInputs .formControl{
	margin-bottom: 0;
}
.configInputs{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 40px;
	width: 100%;
	max-width: 768px;
	margin-top: 20px;
}
.configBig{
	padding: 30px 20px 42px 20px;
}
.configBox h3{
	font-weight: 500;
	font-size: 14px;
	color: #2C3E50;
	margin: 0;
}
.configGrid{
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: 30px;
	margin-top: 30px;
}
.configProfile{
	padding: 30px 20px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}
.configProfile h4{
	margin: 0 0 6px 0;
}
.configProfile p{
	margin: 0;
}
.configUpload{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 130px;
	margin: 0 auto;
	margin-top: 30px;
	padding: 4px;
	border: 0.5px dashed #DDDDDD;
	border-radius: 5px;
	position: relative;
}
.configUpload label{
	cursor: pointer;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	background-color: #DDDDDD;
	border-radius: 5px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.configUpload label:hover{
	opacity: 0.8;
}
.configUpload .MuiBox-root{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: calc(100% - 14px);
	height: calc(100% - 14px);
	overflow: hidden;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.configUpload .MuiBox-root img{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.configBtn{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	background-color: #2C3E50;
	position: absolute;
	z-index: 10;
	margin: auto;
	pointer-events: none;
}
.configBtn img{
	width: 70%;
}
.tabsContainer{
	display: block;
}
.tabsContainer .MuiBox-root{
	padding: 0 !important;
}
.switchItem{
	display: flex;
	align-items: center;
}
.switchItem label span{
	margin: 0;
	display: flex;
	align-items: center;
}
.switchItem label{
	margin-left: 0;
}
.switchItem p{
	margin: 0;
}
.switchItem .MuiSwitch-input {
	left: 0;
	width: auto;
}
.switchItem .MuiSwitch-switchBase{
	top: initial;
}
.switchGrid{
	display: flex;
	align-items: center;
	grid-gap: 30px;
	margin-top: 20px;
}
.configSwitch{
	margin-top: 15px;
	display: block;
}
.configSwitch h4{
	margin: 0;
}
.configCheck{
	display: flex;
	align-items: center;
	background: #F9F9F9;
	border-radius: 5px;
	padding: 10px 20px;
	margin-top: 25px;
}
.configCheck span{
	font-size: 12px;
}

/*** Notificaciones ***/
.subItem{
	display: grid;
	grid-template-columns: 36px 1fr;
	grid-gap: 15px;
}
.subIcon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	background-color: #37ED9A;
}
.subText h3{
	font-size: 14px;
	font-weight: 500;
	margin: 0 0 5px 0;
	color: #02224B;
}
.subText p{
	font-weight: 400;
	font-size: 14px;
	margin: 0;
}
.subTitle{
	font-size: 16px;
	font-weight: 500;
	padding-bottom: 10px;
	border-bottom: 1px solid #DDDDDD;
	color: #02224B;
}
.subContainer .MuiMenu-list{
	padding: 20px 20px 0 20px;
}
.subBottom{
	display: grid;
	grid-template-columns: 36px 1fr 1fr;
	grid-gap: 15px;
	margin-top: 10px;
}
.subBottom span{
	font-size: 12px;
	font-weight: 400;
	color: #8C8C8C;
}
.subBottom a{
	text-align: right;
	font-weight: 400;
	font-size: 12px;
	color: #02224B;
	text-decoration: none !important;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.subBottom a:hover{
	color: #37ED9A;
}
.subRow{
	display: block;
	padding: 20px 0 15px 0;
	border-bottom: 1px solid #DDDDDD;
}
.subAll a{
	display: block;
	text-align: center;
	padding: 20px 0;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none !important;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.subAll a:hover{
	color: #37ED9A;
}
.notiBox{
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	overflow: hidden;
	margin-top: 30px;
}
.notiTop{
	padding: 0 20px;
}
.notiActions {
	padding: 25px 0;
	border-bottom: 1px solid #DDDDDD;
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	align-items: center;
}
.notiActions button{
	margin-left: 20px;
}
.notiActions button:first-of-type{
	margin-left: 5px;
}
.notiActions .MuiSvgIcon-root{
	height: 1.3em;
}
.notiActions span{
	margin-left: -5px !important;
}
.notiContainer{
	display: block;
	width: 100%;
}
.notiList{
	margin: 0;
	padding: 0;
}
.notiRow {
/* 	display: flex; */
	display: grid;
	margin: 0 20px;
	width: calc(100% - 40px);
	border-bottom: 1px solid #DDDDDD;
}
.notiRow .notiButton{
	margin-left: -20px;
	margin-right: -20px;
}
.notiGrid{
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-gap: 9px;
	align-items: center;
	width: auto;
	padding: 20px;
}
.notiGrid .MuiButtonBase-root{
	position: relative;
	margin-left: -9px;
}
.notiItem{
	display: grid;
	grid-template-columns: 48px 1fr;
	grid-gap: 15px;
}
.notiIcon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	border-radius: 100%;
	background-color: #37ED9A;
}
.notiText{
	/* display: flex;
	flex-wrap: wrap; */
	white-space: normal;
	padding-right: 50px;
}
.notiText h3{
	font-size: 14px;
	font-weight: 500;
	margin: 0 0 5px 0;
	color: #02224B;
}
.notiText p{
	font-weight: 400;
	font-size: 14px;
	color: #2C3E50;
	margin: 0;
}
.notiRight{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: right;
	height: 100%;
}
.notiRight span{
	font-size: 12px;
	font-weight: 400;
	color: #828282;
}
.notiRight a{
	text-align: right;
	font-weight: 500;
	font-size: 12px;
	color: #02224B;
	text-decoration: none !important;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.notiRight a:hover{
	color: #37ED9A;
}
.notiButton.disabled{
	background-color: #F2F2F2;
}

/*** Metricas ***/
.cardRow{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0;
}
.cardBottom{
	display: block;
	margin-top: 10px;
}
.metricsGrid{
	display: grid;
	grid-template-columns: 6fr 3fr;
	grid-gap: 24px;
	width: 100%;
	padding: 12px;
}
.metricsItems{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 24px;
}
.metricsVertical{
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
}
.metricsTable{
	display: none;
}

/*** Footer ***/
.footer{
	width: calc(100% - 90px);
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 15px 0 40px 0;
	margin: 0 auto;
	margin-top: 15px;
	border-top: 1px solid #DDDDDD;
}
.footer p{
	font-size: 12px;
	font-weight: 400;
	color: #d1d1d1;
	margin: 0;
}

/*** Cobrador ***/
.cobrarContainer{
	display: block;
	width: 100%;
	max-width: 768px;
	margin: 0 auto;
	margin-top: 30px;
}
.cobrarContainer h2{
	font-weight: 700;
	font-size: 24px;
	margin: 0 0 30px 0;
}
.cobrarContainer h3{
	font-weight: 400;
	font-size: 18px;
	margin: 0 0 30px 0;
}
.cobrarGrid{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}
.cobrarTabs h3{
	margin: 0;
}
.tabsButtons{
	display: block;
	margin-top: 20px;
}
.tabsBtn{
	cursor: pointer;
	padding: 20px;
	display: grid;
	grid-template-columns: 20px 1fr;
	grid-gap: 20px;
	align-items: center;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	margin-bottom: 20px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.tabsBtn:hover{
	background-color: #F9F9F9;
	box-shadow: none;
	-webkit-box-shadow: none;
}
.tabsRadio{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 100%;
	border: 1px solid #8C8C8C;
}
.tabsRadio:before{
	opacity: 0;
	content: '';
	width: 16px;
	height: 16px;
	border-radius: 100%;
	background-color: #37ED9A;
}
.tabsBtn.selected .tabsRadio{
	border-color: #02224B;
}
.tabsBtn.selected .tabsRadio:before{
	opacity: 1;
}
.tabsBtn.inactive{
	background-color: #E8E8E8;
	box-shadow: none;
	-webkit-box-shadow: none;
}
.tabsDetail{
	display: grid;
	grid-template-columns: 28px 1fr;
	grid-gap: 10px;
	align-items: center;
}
.tabsText{
	display: block;
}
.tabsText p{
	margin: 0;
}
.tabsText span{
	display: block;
	color: #8C8C8C;
}
.tabsIcon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
}
.tabsIcon img{
	display: block;
	width: 100%;
}
.cobrarBox{
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	margin-bottom: 20px;
}
.cobrarBox:last-child{
	margin-bottom: 0;
}
.sellerProduct{
	border: 1px solid #e8e8e8;
	height: 120px;
	border-radius: 5px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-bottom: 20px;
}
.sellerRow{
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 20px;
}
.sellerRow:last-child{
	margin-bottom: 0;
}
.sellerRow span{
	display: block;
	color: #798590;

}
.sellerRow p{
	color: #02224B;
	font-size: 16px;
	margin: 0;
	text-align: right;
}
.sellerRow .sellerPrice{
	font-weight: 700;
	font-size: 24px;
	color: #02224B;
	margin: 0;
}
.iframeContainer{
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
}
.personalGrid{
	display: grid;
	grid-template-columns: 230px 1fr;
	grid-gap: 20px;
	margin-bottom: 40px;
}
.personalPrice{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #02BAF1;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
}
.personalRow{
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.personalRow span{
	font-size: 14px;
	font-weight: 400;
	display: block;
	color: #fff;
}
.personalRow p{
	margin: 0;
	font-weight: 700;
	font-size: 16px;
	color: #fff;
}
.personalForm{
	display: grid;
	grid-template-columns: 0.5fr 0.7fr 1fr;
	grid-gap: 20px;
}
.personalForm .formControl{
	margin-bottom: 0;
}
.personalForm .formObs{
	text-align: left !important;
}
.personalLogo{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}
.pagoTop{
	overflow: hidden;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
}
.pagoCover{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #AEFBD8;
	height: 160px;
}
.pagoTitle{
	display: grid;
	grid-template-columns: 90px 1fr;
	grid-gap: 20px;
	align-items: center;
}
.pagoTitle h3{
	margin: 0 !important; 
}
.pagoIcon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90px;
	height: 90px;
}
.pagoAmount{
	display: block;
	padding: 20px;
}
.pagoAmount .pagoRow{
	align-items: center !important;
	margin: 0 !important;
}
.pagoRow{
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: flex-end;
	grid-gap: 10px;
	width: 100%;
	margin: 12px 0;
}
.pagoRow span{
	font-size: 12px;
	font-weight: 400;
	color: #798590;
}
.pagoRow p{
	margin: 0;
	color: #02224B;
}
.pagoRow .pagoPrice{
	font-weight: 700;
	font-size: 18px;
	color: #02224B;
}
.pagoBox{
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	margin-top: 20px;
}
.pagoID{
	display: flex;
	align-items: center;
}
.pagoID p{
	font-weight: 500;
	color: #053B81;
	margin-right: 12px;
}
.pagoRow.pagoStart{
	align-items: flex-start !important;
}
.pagoClient{
	display: flex;
	flex-direction: column;
}
.pagoClient span,
.pagoClient p{
	margin-bottom: 10px;
}
.pagoClient span:last-child{
	margin-bottom: 0;
}
.pagoRow.pagoCenter{
	align-items: center !important;
}
.pagoButton{
	display: block;
	margin-top: 40px;
}
.cobrarSmall{
	max-width: 560px;
}
.pagoPendiente{
	display: grid;
	grid-template-columns: 50px 1fr;
	grid-gap: 20px;
	align-items: center;
	padding: 10px;
}
.pendienteIcon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
}
.pendienteIcon img{
	display: block;
	width: 100%;
}
.pendienteText{
	display: block;
}
.pendienteText h3{
	margin: 0 0 10px 0;
}
.pendienteText p{
	margin: 0;
}
.pendienteText span{
	display: block;
	margin-top: 10px;
	color: #D1D1D1;
}
.pagoError{
	background-color: #FED380;
}
.pagoRechazado{
	background-color: #F9CBD5;
}
.cobrarMobile{
	display: none;
}

/*** Media queries ***/
@media (max-width: 1200px){
	.boxSlider h4{
		font-size: 30px !important;
		line-height: 1.2 !important;
	}
	.MuiCollapse-wrapper .MuiCollapse-wrapperInner{
		padding-bottom: 8px !important;
	}
	.makeStyles-items-28{
		padding: 14px 0 14px 17px !important;
	}
	.makeStyles-nested-33 {
    padding: 12px 15px 12px 40px !important;
	}
	.textSub {
    font-size: 13px !important;
    line-height: 1 !important;
	}
	.textBtn{
		font-size: 13px !important;
	}
	.mobileDrawer{
		width: 240px !important;
	}
	.mobileHeader{
		width: calc(100% - 240px) !important;
	}
	.mobileDrawer .MuiDrawer-paper{
		width: 200px !important;
		margin: 20px !important;
	}
}
@media (max-width: 991px){
	.mobileHeader .growDesktop{
		display: none;
	}
	.toolbarMobile:hover span:nth-child(2){
		width: 30px;
	}
	.toolbarMobile span:last-child{
		margin-bottom: 0;
	}
	.toolbarMobile span:nth-child(2){
		width: 20px;
	}
	.toolbarMobile span{
		display: block;
		width: 26px;
		height: 3px;
		border-radius: 3px;
		background-color: #fff;
		margin-bottom: 6px;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	.toolbarMobile{
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
	}
	.mobileHeader .MuiAppBar-root{
		padding: 0 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.mobileHeader .MuiToolbar-root{
		width: 100%;
		display: flex;
		position: relative;
		justify-content: space-between;
		align-items: center;
	}
	.mobileHeader{
		width: 100% !important;
		z-index: 100 !important;

	}
	.mobileHeader header{
		background: rgb(2,34,75) !important;
		background: linear-gradient(0deg, rgba(2,34,75,1) 0%, rgba(8,56,118,1) 100%) !important;
	}
	.logoMobile {
    display: inline-flex;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    width: 110px;
	}
	.actionMobile{
		display: flex;
		align-items: center;
	}
	.searchMobile{
		display: inline-flex;
		margin-right: 15px;
	}
	.searchDesktop,
	.notiDesktop{
		display: none;
	}
	.notiMobile{
		display: flex;
	}
	.boxSlider{
		height: auto;
	}
	.boxTable{
		margin-bottom: 0 !important;
	}
}
@media (max-width: 768px){
	.form_2, .form_3, .form_1_2{
		grid-template-columns: unset;
	}
	.formContainer.active{
		z-index: 999;
	}
	.searchInput{
		margin-bottom: 20px !important;
	}
	.searchButton button,
	.searchInput{
		width: calc(100% - 60px) !important;
	}
	.searchButton{
		margin-bottom: 20px;
	}
	.searchTitle{
		margin-bottom: 20px;
	}
	.searchInput .MuiInputBase-root,
	.searchInput input{
		width: 100% !important;
		height: 100% !important;
	}
	.searchBox{
		padding: 0 30px;
		width: 100%;
	}
	.navContainer a.MuiButtonBase-root{
		padding: 0 !important;
	}
	.navContainer .MuiListItemText-root{
		padding: 14px 0 14px 17px;
	}
	.avatarContainer .MuiAvatar-circle{
		width: 35px !important;
		height: 35px !important;
	}
	.avatarContainer p span{
		font-size: 12px !important;
	}
	.navContainer{
		margin-top: 15px !important;
		margin-bottom: 15px !important;
	}
	.avatarContainer{
		padding: 10px 15px !important;
	}
	.mobileDrawer .MuiAvatar-img{
		height: 100% !important;
		margin: 0 !important;
	}
	.drawerButton button{
		padding: 8px !important;
	}
	.mobileDrawer img{
		height: 35px !important;
		margin-bottom: 20px !important;
	}
	.mobileDrawer .makeStyles-divider-36{
		height: 30px !important;
	}
	.searchModal{
		position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
	}
	.mobileDrawer{
		height: 100% !important;
	}
	.MuiDrawer-paper.MuiPaper-elevation16{
		box-shadow: none !important;
	}
	.personalLogo{
		margin-bottom: 0;
	}
	.personalForm .formControl:last-child{
		grid-column: span 2;
	}
	.personalForm .formControl{
		margin-bottom: 0;
	}
	.personalForm{
		grid-template-columns: 1fr 1.5fr;
		grid-gap: 20px;
	}
	.personalRow span{
		margin-right: 20px;
	}
	.personalRow{
		justify-content: center;
		align-items: flex-end;
	}
	.personalPrice{
		flex-direction: row;
	}
	.personalGrid{
		grid-template-columns: 1fr;
	}
	.cobrarMobile .pagoCover{
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		height: 130px;
	}
	.pagoInfo p{
		font-size: 12px;
		margin: 0;
	}
	.pagoInfo h3{
		font-size: 18px !important;
		margin: 0 0 10px 0;
	}
	.pagoAlign{
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.pagoAlign p{
		color: #053B81 !important;
		margin: 0 10px 0 0 !important;
	}
	.pagoBlock span{
		display: block;
		color: #798590;
		font-size: 12px;
	}
	.pagoBlock p{
		font-weight: 500;
		font-size: 14px;
		color: #02224B;
		margin: 0 0 5px 0;
	}
	.pagoBlock div:last-child{
		text-align: right;
	}
	.pagoBlock:last-child{
		padding-bottom: 0;
		border: none;
	}
	.pagoBlock{
		padding: 15px 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #d1d1d1;
	}
	.pagoSubtitle h3{
		margin: 0;
		font-size: 18px;
		font-weight: 700;
	}
	.pagoSubtitle{
		padding-bottom: 20px;
		border-bottom: 1px solid #d1d1d1;
	}
	.pagoBoxMobile{
		background-color: #fff;
		padding: 20px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	}
	.cobrarMobile{
		display: block;
	}
	.cobrarDesktop{
		display: none;
	}
	.tabsText span{
		font-size: 12px !important;
	}
	.tabsText p {
    font-size: 14px !important;
	}
	.cobrarContainer h3{
		font-size: 16px !important;
		margin-bottom: 15px;
	}
	.tabsButtons {
    margin-top: 15px;
	}
	.sellerRow p {
    font-size: 14px;
	text-align: right;
	}
	.sellerRow .sellerPrice {
    font-size: 18px;
	}
	.sellerRow {
    padding: 0;
    margin-bottom: 20px;
	}
	.sellerProduct {
    height: 130px;
		margin-top: -20px;
		margin-right: -20px;
		margin-left: -20px;
	}
	.sellerTitle p {
    font-size: 14px !important;
	}
	.sellerTitle h4 {
		font-size: 14px !important;
    margin: 0 0 15px 0;
	}
	.cobrarContainer h2 {
    font-size: 18px;
    margin: 0 0 20px 0;
	}
	.cobrarContainer {
    margin-top: 5px;
	}
	.cobrarInfo{
		grid-row-start: 1;
	}
	.cobrarGrid {
    grid-template-columns: 1fr;
    grid-gap: 30px;
	}
	.notiActions{
		padding: 15px 0;
		justify-content: space-between;
	}
	.notiRight span {
    display: block;
    width: 80px;
    text-align: left;
	}
	.notiRight a{
		display: none;
	}
	.notiRight{
		height: auto;
		padding-left: 70px;
	}
	.notiGrid .MuiIconButton-root{
		padding: 0;
	}
	.notiGrid .MuiButtonBase-root {
    margin-left: -5px;
	}
	.notiText {
    padding-right: 0;
	}
	.notiText h3 {
    font-size: 12px;
	}
	.notiText p {
    font-size: 12px;
	}
	.notiGrid {
    grid-template-columns: 20px 1fr;
		grid-gap: 10px;
		align-items: flex-start;
	}
	.notiItem {
    grid-template-columns: 32px 1fr;
    grid-gap: 10px;
	}
	.notiIcon {
    width: 32px;
    height: 32px;
	}
	.subTitle {
    font-size: 14px;
    padding-bottom: 10px;
	}
	.subBottom {
    grid-template-columns: 32px 1fr 1fr;
    grid-gap: 10px;
	}
	.subItem {
    grid-template-columns: 32px 1fr;
    grid-gap: 10px;
	}
	.subIcon {
    width: 32px;
    height: 32px;
	}
	.subText p {
    font-size: 12px;
	}
	.subText h3 {
    font-size: 12px;
	}
	.subAll a{
		font-size: 12px !important;
	}
	.detallesContainer .movimientosState p:last-child{
		margin-top: 0;
	}
	.mobileContainer .detallesContainer{
		max-width: initial;
		padding: 0;
	}
	.financeArrow{
		display: block;
		text-align: right;
	}
	.financeRight .red{
		color: #C80731;
	}
	.financeRight .green{
		color: #00BB66;
	}
	.financeRight{
		display: flex;
		align-items: flex-end;
		flex-direction: column;
	}
	.financeRight span{
		font-size: 12px;
		text-align: right;
		color: #8C8C8C;
	}
	.financeRight p{
		font-size: 12px;
		text-align: right;
		margin: 0;
	}
	.financeLeft span{
		font-size: 12px;
		color: #053B81;
	}
	.financeLeft p{
		font-size: 12px;
		margin: 0;
	}
	.financeLeft{
		display: flex;
		flex-direction: column;
	}
	.financeRow{
		display: grid;
		grid-template-columns: 1fr 1fr 30px;
		align-items: center;
		padding: 10px 15px;
		border-top: 1px solid #d1d1d1;
	}
	.financeTable{
		margin-top: 20px;
		display: block;
		background: #FFFFFF;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		overflow: hidden;
	}
	.financeMobile{
		display: block;
	}
	.financeHide{
		display: none !important;
	}
	.metricsDesktop{
		display: none;
	}
	.metricsRow:last-child{
		border-bottom: none;
	}
	.metricsIcon{
		display: inline-flex;
		margin-right: 5px;
	}
	.metricsRow div:nth-child(2){
		text-align: center;
	}
	.metricsRow div:nth-child(3){
		text-align: right;
		color: #02224B !important;
	}
	.metricsRow div:first-child{
		display: flex;
		align-items: center;
	}
	.metricsRow div:first-child span{
		display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
		overflow: hidden;
	}
	.metricsRow div{
		font-size: 12px;
		font-weight: 400;
	}
	.metricsRow{
		display: grid;
		grid-template-columns: 1.8fr 1fr 1fr;
		grid-gap: 15px;
		align-items: center;
		padding: 20px 15px;
		border-bottom: 1px solid #d1d1d1;
	}
	.metricsDate span{
		font-size: 12px;
		font-weight: 400;
		text-align: right;
		color: #798590;
	}
	.metricsDate{
		padding: 10px 15px;
		border-bottom: 1px solid #d1d1d1;
		display: flex;
		justify-content: flex-end;
	}
	.metricsTop h3{
		color: #798590;
		margin: 0;
		font-size: 14px;
		font-weight: 500;
	}
	.metricsTop{
		padding: 20px 15px 15px 15px;
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 10px;
		border-bottom: 1px solid #d1d1d1;
	}
	.metricsHeader div{
		font-size: 12px;
		font-weight: 400;
	}
	.metricsHeader div:nth-child(2){
		text-align: center;
	}
	.metricsHeader div:nth-child(3){
		text-align: right;
	}
	.metricsHeader{
		display: grid;
		grid-template-columns: 1.8fr 1fr 1fr;
		grid-gap: 15px;
		align-items: center;
		padding: 20px 15px;
		border-bottom: 1px solid #d1d1d1;
	}
	.metricsTable{
		display: block;
		background: #FFFFFF;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		overflow: hidden;
	}
	.metricsGrid .movimientosResumen{
		display: none;
	}
	.cardRow h5{
		font-size: 18px !important;
	}
	.metricsCard p{
		font-size: 14px !important;
	}
	.cardBottom p{
		font-size: 12px !important;
	}
	.filtersHide{
		display: none;
	}
	.metricsGrid{
		grid-template-columns: 1fr;
	}
	.metricsVertical {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
	}
	.metricsItems{
		grid-template-columns: 1fr;
		grid-gap: 20px;
	}
	.datosForm a{
		font-size: 14px !important;
	}
	.datosForm{
		padding: 30px 20px;
	}
	.datosRow .formControl{
		margin-bottom: 0;
	}
	.datosRow{
		max-width: initial;
		grid-template-columns: 1fr;
		grid-gap: 20px;
		margin-bottom: 20px;
	}
	.datosBtn {
    right: -12px;
	}
	.datosProfile{
		text-align: center;
		padding: 20px;
	}
	.datosUpload{
		margin-top: 30px;
		width: 84px;
		height: 84px;
	}
	.datosGrid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
	}
	.comunicacionesSingle{
		padding: 20px;
	}
	.comunicacionesRow label{
		margin-right: 10px;
	}
	.comunicacionesRow p{
		font-size: 12px !important;
	}
	.switchItem label{
		margin-right: 10px;
	}
	.switchItem p {
    font-size: 12px !important;
	}
	.switchGrid{
		flex-direction: column;
		align-items: flex-start;
		grid-gap: 20px;
	}
	.configUpload{
		margin-top: 20px;
	}
	.configGrid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 20px;
	}
	.configBig {
    padding: 30px 20px 20px 20px;
	}
	.configInputs .formSelect .MuiInputBase-root{
		width: 100%;
	}
	.configInputs .formSelect{
		width: 100%;
	}
	.configInputs {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin-top: 20px;
	}
	.userTab span svg{
		width: 100%;
	}
	.userTab span{
		font-size: 12px !important;
		grid-template-columns: 15px 1fr;
		grid-gap: 8px;
	}
	.configSocial{
		display: none;
	}
	.configLinks {
    grid-template-columns: 1fr;
    grid-gap: 0;
	}
	.profileCover {
    align-items: center;
    justify-content: center;
		padding: 0 20px;
	}
	.profileTop {
		max-width: initial;
		}
	.profileContainer {
    max-width: initial;
    margin-top: 60px;
	}
	.mobileContainer .profileWrapper{
		padding: 0 !important;
	}
	.profileInfo {
    width: 100%;
    max-width: 260px;
	position: absolute;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
    align-content: center;
    justify-content: center;
	flex-direction: column;
	top: 62px;
	}
	.profileIcon {
    width: 100px;
    height: 100px;
	}
	.profileIcon img{
		width: 100%;
		height: auto;
	}
	.profileInfo h3 {
    font-size: 18px;
		margin: 0;
	}
	.linkCover{
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}
	.linkHidden .linkRounded span {
    margin-bottom: 0;
	}
	.linkHidden .linkRounded{
		display: grid;
		grid-template-columns: repeat(3,1fr);
		align-items: center;
	}
	.linkHidden{
		margin-top: 20px;
		width: calc(100% - 40px);
		display: block;
		padding: 20px;
		display: block;
		background: #FFFFFF;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		overflow: hidden;
	}
	.linkURL .shareForm{
		width: calc(100% - 40px);
		padding: 20px;
		display: block;
		background: #FFFFFF;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		overflow: hidden;
	}
	.linkWrapper .shareFooter{
		padding: 20px;
		display: block;
		background: #FFFFFF;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		overflow: hidden;
	}
	.linkBg{
		display: block;
		background: #FFFFFF;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		overflow: hidden;
	}
	.proSingle {
    margin-top: 20px;
    display: block;
	}
	.linkForm .tabObs {
		display: none;
	}
	.linkForm .proContainer .MuiAccordionDetails-root{
		padding-bottom: 0;
	}
	.linkTitle {
    display: block;
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 0;
    border-bottom: none
	}
	.linkTop .MuiButton-label{
		position: relative;
	}
	.linkTop .MuiButton-sizeSmall {
    width: auto !important;
    height: 26px !important;
    padding: 5px 10px;
    border-radius: 3px;
    letter-spacing: 1px;
    text-transform: none;
}
	.linkTop .makeStyles-root-48 div:last-child{
		width: calc(100% - 30px);
		left: 0;
		margin: 0;
		right: inherit;
	}
	.linkTop .MuiPaper-root{
		width: auto !important;
	}
	.linkTop .MuiButton-endIcon{
		position: absolute;
		right: 0;
	}
	.linkTop .MuiButton-root{
		font-size: 16px !important;
		height: 50px;
    justify-content: flex-start;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	}
	.linkTop .MuiButton-root,
	.linkTop .makeStyles-root-48 div:first-child{
		width: 100%;
	}
	.linkTop .makeStyles-root-48{
		width: 100%;
		margin: 0;
	}
	.linkTop .linkRounded{
		display: none;
	}
	.linkFilter .linkActions{
		width: 100%;
	}
	.linkFilter .linkActions div:first-child{
		padding: 0 !important;
		display: block;
		width: calc(100vw - 30px) !important;
	}
	.linkFilter .linkActions button,
	.linkFilter .linkActions div:first-child{
		width: 100%;
		margin: 0;
	}
	.linkFilter .linkActions .MuiFormControlLabel-root{
		width: 100%;
	}
	.linkFilter {
    display: flex;
		justify-content: flex-start;
		width: 100%;
		margin: 15px 0 20px 0;
	}
	.linkForm{
		display: block;
		background: #FFFFFF;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		overflow: hidden;
		padding: 30px 20px;
	}
	.linkTop {
    grid-template-columns: 1fr;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
	}
	.linkWrapper {
    padding: 30px 0 0 0;
	}
	.linkBox{
		background: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 0;
	}
	.MuiAccordion-rounded{
		border-bottom: none;
	}
	.linkTop h3 {
    font-size: 18px !important;
	}
	.listRight .colabUser:hover .colabEdit {
    top: 30px;
    right: 0;
	}
	.linkActions {
    width: 80px;
	}
	.linkRounded span:last-child{
		margin-bottom: 0;
	}
	.linkRounded span{
		margin-bottom: 5px;
	}
	.linkRounded .MuiButton-label span {
    font-size: 12px;
    margin-left: 5px;
    position: absolute;
		left: 30px;
		margin-bottom: 0;
	}
	.linkRounded{
		flex-direction: column;
		margin-left: 0;
	}
	.linkActions .buttonSecondary{
		display: none !important;
	}
	.linkHidden{
		display: inline-block
	}
	.linkPrev img{
		display: block !important;
		width: auto !important;
		margin: 0 !important;
	}
	.linkPrev{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		position: relative;
		margin-right: 10px !important;
	}
	.listAmount{
		display: inline-flex;
		flex-direction: column;
		align-items: flex-end;
		margin-right: 10px;
	}
	.listRight .colabUser{
		width: 20px !important;
		align-items: center;
		justify-content: center;
	}
	.listRight{
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.listAmount span{
		font-size: 12px !important;
		color: #8C8C8C;
	}
	.listAmount p{
		margin: 0;
		font-size: 14px !important;
		color: #02224B;
	}
	.linkPreview span{
		font-size: 12px !important;
	}
	.linkPreview p{
		margin: 0;
		font-size: 14px !important;
		display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
		overflow: hidden;
	}
	.linkMobile{
		display: block;
		padding-bottom: 150px;
	}
	.linkDesktop{
		display: none;
	}
	.footerRow a{
		margin: 20px 0 0 0;
	}
	.footerRow{
		padding: 15px;
		flex-direction: column;
		align-items: flex-start;
	}
	.linkShare .shareIcons button{
		margin-top: 0;
	}
	.linkShare .shareIcons{
		margin-top: 0;
		flex-direction: initial;
		justify-content: center;
	}
	.linkURL button{
		margin-top: 15px;
	}
	.linkDetail span {
    margin-top: 15px;
	}
	.linkDetail h3 {
    font-size: 18px !important;
    margin: 0 0 5px 0;
	}
	.linkDetail p{
		font-size: 14px !important;
	}
	.linkDetail{
		padding: 30px 20px;
	}
	.linkURL .shareFooter{
		margin-top: 20px;
		padding: 20px;
	}
	.linkImg .coverBg{
		position: relative;
	}
	.linkURL{
		margin: 0;
	}
	.linkImg {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    height: auto;
	}
	.linkProduct {
    grid-template-columns: 1fr;
    grid-gap: 0;
    padding: 0;
    height: auto;
    background: transparent;
	}
	.linkShare{
		grid-template-columns: 1fr;
	}
	.linkTitle h3 {
    font-size: 18px;
	}
	.proColumns {
    grid-template-columns: 1fr;
    grid-gap: 20px;
	}
	.formControl .formUpload{
		width: 100%;
	}
	.linkColumns .formControl{
		margin-bottom: 0;
	}
	.linkColumns {
    grid-template-columns: 1fr;
    grid-gap: 30px;
		margin-bottom: 30px;
	}
	.modalFilter .Mui-expanded .filterSelected span{
		display: none;
	}
	.filterRadio{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 5px;
	}
	.filterRadio svg{
		width: 14px;
		height: 14px;
	}
	.filterSelected{
		padding-left: 15px;
	}
	.modalFilter .MuiAccordionDetails-root{
		padding: 0;
	}
	.filterOptions p{
		font-size: 12px;
		font-weight: 400;
		margin: 0;
	}
	.filterOptions:first-of-type{
		margin-top: -10px;
		padding-top: 0;
		border: none;
	}
	.filterOptions{
		border-top: 1px solid #d1d1d1;
		padding: 5px 0 5px 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.modalButton{
		display: block;
		text-align: center;
		margin-top: 20px;
	}
	.modalFilter .tabObs {
    margin: 0;
	}
	.modalFilter .accordionTab i{
		position: absolute;
		right: 15px;
	}
	.modalFilter .MuiAccordionSummary-content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
	}
	.modalFilter .MuiAccordion-rounded{
		padding: 0;
		margin-bottom: -1px !important;
	}
	.modalClose{
		position: absolute;
		top: 15px;
		right: 15px;
	}
	.filterTop a{
		text-align: right;
		color: #798590;
		display: block;
		font-size: 12px;
		font-weight: 400;
		text-decoration: none !important;
	}
	.filterTop h3{
		margin: 0;
		font-weight: 500;
		font-size: 14px;
	}
	.filterTop{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
		padding: 20px 15px;
	}
	.modalFilter{
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		padding: 30px 0 20px 0;
		position: relative;
		width: calc(100% - 40px) !important;
	}
	.sellerDetail{
		align-items: flex-start;
	}
	.sellerDetail a {
    margin: 5px 0 0 0;
	}
	.sellerInfo{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.movimientosSeller{
		margin-bottom: 20px !important;
	}
	.movimientosIcon{
		margin-right: 10px;
	}
	.movimientosUser a {
		margin: 5px 0 5px 50px;
	}
	.movimientosUser{
		margin-bottom: 20px !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: flex-start !important;
	}
	.movimientosState p:last-child {
    margin-top: 15px;
    margin-left: 0;
	}
	.stateAlign{
		width: 100%;
		justify-content: space-between;
	}
	.movimientosState{
		flex-direction: column;
		align-items: initial;
	}
	.movimientosID{
		justify-content: space-between;
	}
	.movimientosDetail{
		grid-template-columns: 1fr;
	}
	.movimientosMobile .MuiGrid-grid-md-4,
	.movimientosMobile .MuiGrid-grid-lg-4{
		width: 100%;
	}
	.movimientosBox{
		width: 100%;
	}
	.movimientosBlock{
		width: 100%;
	}
	.movDetail .pending{
		display: none !important;
	}
	.movPending .pending{
		display: flex !important;
    height: 17px;
    align-items: center;
	}
	.tableTag{
		display: block;
	}
	.tagRed{
		color: #EB5757;
	}
	.tagGreen{
		color: #00BB66;
	}
	.tagPending{
		color: #F2994A;
	}
	.movRight{
		text-align: right;
	}
	.movAmount span{
		font-size: 12px !important;
		color: #8C8C8C;
	}
	.movAmount p{
		margin: 0;
		font-size: 14px !important;
		color: #02224B;
	}
	.movOut .out {
    display: flex !important;
    height: 17px;
    align-items: center;
	}
	.movOut .in{
		display: none !important;
	}
	.movIn .out{
		display: none !important;
	}
	.movIn .in {
    display: flex !important;
    height: 17px;
    align-items: center;
	}
	.movPending .in,
	.movPending .out{
		display: none !important;
	}
	.movDetail{
		display: grid;
		grid-template-columns: 10px 1fr;
		grid-gap: 5px;
	}
	.movDetail span{
		font-size: 12px !important;
	}
	.movDetail p{
		margin: 0;
		font-size: 14px !important;
		display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
		overflow: hidden;
	}
	.tableRow{
		display: grid;
		grid-template-columns: 1.2fr 1fr;
		grid-gap: 10px;
		padding: 20px 15px;
		border-top: 1px solid #d1d1d1;
	}
	.filterNumber{
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: #2C3E50;
		border-radius: 100%;
		color: #fff;
		font-size: 12px !important;
		top: -12px;
    right: -10px;
	}
	.filterIcon{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-left: 10px;
	}
	.tableFilter span{
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
	}
	.tableFilter{
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.tableHeader h3{
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #798590;
		margin: 0;
	}
	.tableHeader{
		padding: 30px 30px 20px 15px;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	.movimientosMobile .MuiGrid-item {
    padding: 0 !important;
	}
	.movimientosMobile{
		margin: 0 !important;
		width: 100% !important;
	}
	.tableMobile .paginationBox{
		margin-top: 20px;
	}
	.tableMobile .paginationBox .MuiPagination-ul{
		justify-content: center;
	}
	.tableContainer{
		width: 100%;
		background: #FFFFFF;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
	}
	.tableMobile{
		margin-top: 20px;
		box-sizing: border-box;
		display: block;
		width: 100%;
	}
	.fullMobile{
		display: block;
		width: 100%;
	}
	.tableDesktop,
	.filterDesktop{
		display: none !important;
	}
	.modalTitle h3 {
    font-size: 14px !important;
	}
	.modalBox .shareIcon svg{
		width: 15px !important;
		height: 15px !important;
	}
	.modalBox .shareIcon {
    width: 30px;
    height: 30px;
	}
	.modalBox .shareIcons{
		flex-direction: initial;
		justify-content: center;
	}
	.modalInputs .formControl:last-child{
		margin-bottom: 0;
	}
	.modalInputs .formControl{
		margin-bottom: 20px;
	}
	.modalInputs{
		display: block;
	}
	.modalBox{
		width: calc(100% - 100px);
		height: 80vh;
		overflow: auto;
	}
	.colabSettings {
    margin-top: 20px;
	}
	.colabSwitch{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-left: 10px;
	}
	.footerButtons {
    margin-top: 20px;
	}
	.colabRol h3{
		font-size: 14px !important;
	}
	.colabEdit{
		right: 0;
	}
	.colabUser:hover .colabEdit {
    top: 25px;
	}
	.colabInput .formControl:last-child{
		margin-bottom: 0;
	}
	.colabInput .formControl{
		margin-bottom: 20px;
	}
	.colabUser{
		grid-row-start: 1;
		width: 100%;
		justify-content: flex-end;
	}
	.colabInput{
		display: block;
	}
	.colabData{
		grid-template-columns: 1fr;
	}
	.footer p:last-child{
		text-align: right;
	}
	.footer {
    width: calc(100% - 40px);
		grid-gap: 30px;
	}
	.colabHeader,
	.colabRow{
		grid-template-columns: 1.35fr 1fr 1px 1fr;
	}
	.colabActions {
    margin-right: 10px;
	}
	.colabActions .linkRounded{
		flex-direction: row;
	}
	.colabButton{
		margin: 0;
	}
	.colabHeader p,
	.colabRow p,
	.colabRow a{
		font-size: 12px !important;
	}
	.colabHeader div:nth-child(3),
	.colabRow div:nth-child(3){
		font-size: 0;
		opacity: 0;
	}
	.requisitosRow p{
		font-size: 12px !important;
	}
	.requisitosIcon img{
		width: 100%;
		height: 100%;
	}
	.requisitosIcon {
    width: 50px;
    height: 50px;
	}
	.requisitosRow {
    grid-template-columns: 50px 1fr;
    grid-gap: 12px;
    margin-bottom: 20px;
	}
	.requisitosInfo h5{
		font-size: 18px !important;
		line-height: 1.4 !important;
	}
	.requisitosGrid .requisitosBg,
	.requisitosGrid .ayudaBg {
    position: absolute;
    right: 0;
		left: initial;
    bottom: -20px;
    height: 80%;
    width: 80%;
    background-color: #37ED9A;
    border-radius: 10px;
    z-index: 0;
	}
	.requisitosGrid .requisitosImg .coverBg{
		width: calc(100% - 20px);
		border-radius: 10px;
		overflow: hidden;
		padding: 0 !important;
	}
	.requisitosGrid .requisitosImg{
		height: 260px;
	}
	.requisitosImg{
		grid-row-start: 1;
	}
	.requisitosGrid{
		grid-template-columns: 1fr;
		padding-bottom: 0;
	}
	.mobileContainer{
		margin-bottom: 20px !important;
	}
	.shareGrid .shareBg {
    position: absolute;
    right: 0;
    bottom: -20px;
    height: 80%;
    width: 80%;
    background-color: #37ED9A;
    border-radius: 10px;
    z-index: 0;
	}
	.shareGrid .shareImg .coverBg{
		width: calc(100% - 20px);
		border-radius: 10px;
		overflow: hidden;
		padding: 0 !important;
	}
	.shareGrid .shareImg{
		height: 260px;
	}
	.shareTitle P{
		margin: 10px 0 0 0 !important;
	}
	.shareTitle h5{
		font-size: 18px !important;
	}
	.shareGrid .shareIcons {
    align-items: flex-start;
    justify-content: center;
    flex-direction: initial;
	}
	.shareButton svg {
    	margin-right: 0 !important;
		/* width: 30px !important; */
		height: 30px !important;
	}
	.shareButton .MuiButton-label{
		/* display: block !important; */
	}
	.shareButton span{
		/* display: none; */
	}
	.shareIcons .MuiButton-root{
		width: auto !important;
		min-width: initial !important;
		padding: 0 !important;
		height: auto !important;
		background: transparent !important;
	}
	.shareIcons{
		margin-top: 20px;
	}
	.shareInfo{
		padding-top: 10px;
		margin-bottom: 0;
	}
	.shareForm {
    margin-top: 20px;
    width: 100%;
    max-width: initial;
	}
	.shareFooter{
		text-align: center;
	}
	.shareGrid {
    grid-template-columns: 1fr;
	}
	.passBox p{
		text-align: center;
		font-size: 14px !important;
	}
	.passBox a{
		margin: 0 auto;
		margin-top: 20px;
	}
	.passBox {
    display: flex;
    flex-direction: column;
	}
	.bannerButton .MuiButton-root{
		font-size: 14px !important;
	}
	.bannerButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
	}
	.bannerText{
		display: grid;
		grid-template-columns: 50px 1fr;
		grid-gap: 15px;
		margin-bottom: 8px;
	}
	.bannerColored p{
		font-size: 14px !important;
	}
	.bannerColored{
		display: block;
	}
	.alertIcon{
		width: 60px;
		height: 60px;
	}
	.alertBg{
		height: 145px;
	}
	.alertWrapper{
		display: block;
		overflow: hidden;
		border-radius: 10px;
		width: calc(100% - 40px);
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		background-color: #FFFFFF !important;
	}
	.formAlert.formModal{
		position: fixed;
		z-index: 100;
		padding: 0 !important;
	}
	.formCta .shareIcons button{
		margin: 0 0 10px 0 !important;
	}
	.shareIcons{
		flex-direction: column;
		align-items: flex-start;
	}
	.formClose{
		display: block;
		position: absolute;
		top: 15px;
		right: 15px;
	}
	.formModal .formWrapper{
		margin: 0;
		width: calc(100% - 40px);
	}
	.formModal{
		padding: 0 20px !important;
		height: 100% !important;
		background-color: rgba(0, 0, 0, 0.5) !important;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
	}
	.formControl .formUpload{
		font-size: 12px;
	}
	.requisitosText{
		margin-top: 20px;
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		width: calc(100% - 40px);
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	}
	.formSuccess{
		position: fixed;
		top: initial !important;
		bottom: 0;
		left: 0;
		height: calc(100% - 70px);
		width: calc(100% - 40px);
		padding: 0 20px;
		background-color: #f3f3f3;
	}
	.formContainer button {
    margin-top: 30px;
	}
	.form_3_2 {
    grid-template-columns: 1fr;
    grid-gap: 25px;
	}
	.formNav p{
		font-size: 16px;
		font-weight: 400;
		color: #fff;
		margin: 20px 0;
	}
	.formNav img{
		display: flex;
		margin-right: 10px;
	}
	.formNav{
		padding: 0 20px;
		margin: -30px -20px 0 -20px;
		display: flex;
		height: 70px;
		align-items: center;
		background: linear-gradient(180deg, #083876 0%, #02224B 100%);
	}
	.requisitosForm{
		position: absolute;
		top: 0;
		left: 0;
	}
	.formWrapper{
		position: relative;
		margin: 20px 0;
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
	}
	.formContainer{
		position: fixed;
		top: 0;
		left: 0;
		width: calc(100% - 40px);
		z-index: 20;
		border-radius: 0;
		box-shadow: none;
		-webkit-box-shadow: none;
		background-color: #f3f3f3;
		height: calc(100% - 30px);
		overflow-y: auto;
	}
	.topTitle p{
		font-size: 12px !important;
	}
	.topTitle h5{
		font-size: 18px !important;
	}
	.requisitosTab {
		display: block;
		position: relative;
	}
	.recomendaLogo{
		height: 40px;
		width: 40px;
	}
	.sliderDots h4{
		font-size: 34px !important;
	}
	.boxSlider .Carousel-indicators-66{
		bottom: 20px !important;
		position: relative;
	}
	.boxTable{
		margin-bottom: 0;
	}
	.boxLink a{
		width: 100%;
		display: flex;
	}
	.boxSlider .Carousel-indicators-72,
	.boxSlider .Carousel-indicators-74{
		margin: 0 0 20px 0;
	}
	.boxSlider{
		height: auto;
	}
	.mobileContainer{
		padding: 20px 15px !important;
	}
	.loginLogo{
		position: relative;
	}
	.loginLogo img{
		padding: 0;
		height: 50px;
		margin-bottom: 20px;
	}
	.CarouselItem h5{
		padding: 0 50px;
		font-size: 18px;
	}
	.loginLogo{
		display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: initial;
    text-align: center;
	}
	.loginContainer .makeStyles-paper-9{
		height: auto;
		margin: 0 !important;
	}
	.loginContainer .makeStyles-wrappedImage-13{
		height: auto;
	}
	.loginContainer .Carousel-root-16{
		height: auto;
	}
	.loginContainer .homeBtn p{
		font-size: 12px !important;
	}
	.CarouselItem{
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.homeBtn {
    margin-top: 30px !important;
	}
}

.linkListImg{
max-width:40px;
max-height:42px;
height: auto;
width:auto;
}
.linkConfirmImg{
	max-width:191px;
	max-height:160px;
	height: auto !important; 
	width:auto !important; 
}

.planBtnDetail{
/* 	cursor: pointer; */
	padding: 20px;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 20px;
	align-items: center;
	background-color: #fff;
	border-radius: 10px;
	margin-bottom: 10px;
	border: 1px solid #D1D1D1;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.planBtnDetail:hover{
	background-color: #F9F9F9;
	box-shadow: none;
	-webkit-box-shadow: none;
}
.planBtnDetail.selected {
	background: #02224B;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
}
.bodyLM.default.selected{
	color: #37ED9A !important;
}
.bodyXS.blue.selected{
	color: #FFFFFF !important;
}
.bodySM.default.selected{
	color: #37ED9A !important;
}
.planRight.selected .planPrice h5{
	color: #37ED9A !important;
}
.planRight.selected p{
	color: #FFFFFF !important;
}
.planRight.selected p{
	color: #FFFFFF !important;
}
.planBtnDetail.selected .tabsRadio{
	border-color: #02224B;
}
.planBtnDetail.selected .planRadio:before{
	opacity: 1;
}
.planBtnDetail.inactive{
	background-color: #E8E8E8;
	box-shadow: none;
	-webkit-box-shadow: none;
}
.planText2{
	display: block;
	width: 100%;
	max-width: 400px;
}
.planText2 p{
	margin: 0;
}
.planText2 span{
	display: block;
	color: #8C8C8C;
}
.titlePayDueBalance{
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
}

.invoiceLeft{
	display: flex;
	flex-direction: column;
}

.invoiceLeft p{
	font-size: 12px;
	margin: 0;
}

.invoiceLeft span{
	font-size: 12px;
}
.profileWrapper{
	background: #F9F9F9;
}

@media print {
	html, body {
	  height: initial !important;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  margin:  20mm;
	}
  }
  .tableRowAcreditation{
	display: grid !important;
	grid-template-columns: 1.2fr 1fr;
	grid-gap: 10px;
	padding: 10px 0px;
	border-bottom: 1px solid #DDDDDD;
}
a.tableRowAcreditation div.movRight{
	text-align: right;
}
.cardBottomAcreditation{
	display: block;
	/* margin-top: 22px; */
}
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: auto;
	} 
  }
  @page {
	size: auto;
  }

  .carousel-button {
	display: flex;
	align-self: center;
	cursor: pointer;
	background: transparent;
	border: none;
  }
  
  .carousel-button.next{
	padding-top: 10px;
  }
  .carousel-button svg {
	height: 20px;
	width: 20px;
	color: #798590 ;
  }
  .outer-container {
	margin: auto;
	display: flex;
	overflow: hidden;
	margin: 15px 0;
  }
  
  .outer-container > div {
	flex: 1;
  }
  
  .outer-container .carousel-wrapper {
	display: flex;
	flex-direction: column;
  }
  
  .carousel {
	height: 100%;
	display: flex;
	flex: 4;
	align-items: center;
  }

  .slides {
	align-self: flex-start;
	flex: 1;
	width: 100%;
	overflow: hidden;
  }

  .carousel-inner {
	position: relative;
	max-height: 180px;
	height: 180px;
  }

  .carousel-inner::before,
.carousel-inner::after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 110px;
  pointer-events: none;
}

.carousel-inner::before {
  top: 0;
}

.carousel-item {
	position: absolute;
	width: 100%;
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	transition: transform 0.4s ease, opacity 0.4s ease;
  }
  